// pages/patient.js
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql　} from "gatsby"


const profilePage = ({ data }) => (
  <Layout>
  <SEO title="Profile" image={data.allMicrocmsTop.edges[0].node.main_photo[0].image.url} />
  <div className="p_profile contents padding">
    <div className="p_profile_inner contents_inner inner">
        <div className="p_page_head inner">
         <h1 className="p_page_head_ttl">Profile</h1>
        </div>
        <div className="p_profile_box">
          <section className="p_profile_block ja">
            <h2 className="p_profile_block_ttl">藤井 春日</h2>
            <p className="p_profile_block_para">東京生まれ。武蔵野美術大学を卒業後、写真表現の道へと進む。<br />写真、ムービー撮影等、活動のシーンは多岐にわたるが、とりわけ書籍という閉じられた空間への表現に深い関心を寄せる。</p>
          </section>
          <section className="p_profile_block ja small">
            <h2 className="p_profile_block_ttl">Haruhi FUJII</h2>
            <p className="p_profile_block_para">Born in Tokyo, Fujii began her career in visual creative activities after graduating from Musashino Art University.</p>
            <p className="p_profile_block_para">While she has worked in a diverse range of areas, such as photography and film, she also possesses an intense interest in the expressions used within the confined space found in books.</p>
          </section>
        </div>
    </div>
  </div>
  </Layout>
)


export const query = graphql`
 {
  allMicrocmsTop {
    edges {
      node {
        main_photo {
          image {
            url
          }
        }
      }
    }
  }
 }
`


export default profilePage